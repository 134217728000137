<template>
    <div class="row main-row d-flex flex-row justify-content-between align-items-center">
      <div class="col-12 col-md-6 bg-white d-none d-md-block">
        <div class="d-flex justify-content-center align-items-center main-row ">
          <div class="d-flex align-items-center">
            <b-img fluid :src="imgUrl" alt="Login V2" height="500" width="300" />

          </div>
        </div>
      </div>
      <div class="col-12 col-md-6 bg-light">
        <div class="d-flex justify-content-center align-items-center main-row ">
          <div class="login-area  bg-white px-2 py-4">
            <div>
              <b-card-title class="mb-1 text-center forgot-text ">
                Forgot Passaword

              </b-card-title>
              <b-card-text class="mb-2 text-muted  card-text instruction-text">
                Please enter your email address here. A password reseting link
                will be sent to that mail.
              </b-card-text>
              <p v-if="errorMsg" class="text-danger">{{ errorMsg }}</p>
              <div v-if="alert">
                <p class="text-success text-center"> {{ alert }}</p>
              </div>
              <!-- form -->
              <validation-observer ref="simpleRules" #default="{ invalid }">
                <b-form class="auth-forgot-password-form form-area" @submit.prevent="validationForm">
                  <b-form-group class="form-label" label="Email Address" label-for="forgot-password-email">
                    <validation-provider #default="{ errors }" name="Email" rules="required|email">
                      <b-form-input id="forgot-password-email" v-model="userEmail" :state="errors.length > 0 ? false : null"
                        name="forgot-password-email" placeholder="john@example.com" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                  <b-form-group class="d-flex justify-content-center mt-3">
                    <b-button type="submit" :disabled="invalid" class="btn btn-color rounded px-5">Send</b-button>
                  </b-form-group>
                </b-form>
              </validation-observer>

              <p class="text-center mt-2 text-muted alreday-account">
                <span>Already have an account?</span>
              </p>
              <p class="text-center mt-2 alreday-account">
                <b-link :to="{ name: 'auth-login' }">
                  <span class="sign-in-color">&nbsp;Sign in </span>
                  <span class="text-muted">quickly</span>
                </b-link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
/* eslint-disable global-require */
import store from "@/store/index";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import VuexyLogo from "@core/layouts/components/Logo.vue";
import { email, required } from "@validations";
import {
BButton,
BCardText,
BCardTitle,
BCol,
BForm,
BFormGroup,
BFormInput,
BImg,
BLink,
BRow
} from "bootstrap-vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";

export default {
  components: {
    VuexyLogo,
    BRow,
    BCol,
    BLink,
    BImg,
    BForm,
    BButton,
    BFormGroup,
    BFormInput,
    BCardTitle,
    BCardText,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      errorMsg: "",
      alert: "",
      userEmail: "",
      sideImg: require("@/assets/images/logo/logo-main.png"),
      // validation
      required,
      email,
    };
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require("@/assets/images/logo/logo-main.png");
        return this.sideImg;
      }
      return this.sideImg;
    },
  },
  methods: {
    validationForm() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          this.$store.dispatch("auth/forgetPassword", {
            email: this.userEmail,
          }).then(() => {
            //this.$router.push("/login");
            this.alert = "Password reset link sent to your email"
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Password reset link sent to your email",
                icon: "CheckIcon",
                variant: "success",
              },
            });
          }).catch((error) => {
            this.errorMsg = "please give a valid mail"

            this.$toast({
              component: ToastificationContent,
              props: {
                title: error.response.data.message,
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@core/scss/vue/pages/page-auth.scss";

.form-area {
  padding: 80px;
}

.instruction-text {
  margin-top: 100px;
  margin-left: 78px;
}

.login-area {
  height: 800px !important;
  width: 700px !important;

}

@media only screen and (max-width: 1440px) and (min-width: 1050px) {
  .login-area {
    height: 600px !important;
    width: 450px !important;

  }

  .form-area {
    padding: 10px;
  }

  .instruction-text {
    margin-left: 15px;
  }
}

.forgot-text {
  background: -webkit-linear-gradient(#01185e, #264296, #7190ef);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 53px;
}

.sign-in-color {
  background: -webkit-linear-gradient(#01185e, #264296, #7190ef);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  font-family: "Segoe UI";
  font-weight: 700 !important;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  text-decoration-line: underline !important;
  color: #264296;
}

.btn-color {
  background: linear-gradient(315deg, #01185e 0%, #264296 44.92%, #7190ef 100%);
  font-size: 13px;
}

.card-text {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #A8ADAF;
}

.form-label label {
  font-weight: 400;
  font-size: 20px !important;
  line-height: 27px;
  color: #515B60;
}

.alreday-account span {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #A8ADAF;
}

#forgot-password-email {
  background: #F9F9F9;
  border-radius: 4px;
}

.main-row {
  height: 100vh !important;
  overflow: hidden !important;
}
</style>
